import homeIcon from "assets/home.svg";
import clipboardTextIcon from "assets/clipboard-text.svg";
import headphoneIcon from "assets/headphones.svg";
import productSourceIcon from "assets/product-source-box.svg";
import cloutTrainingIcon from "assets/training.svg";
import locationMarkerIcon from 'assets/lightbulb-filament.svg';
import noteIcon from 'assets/note.svg';
import chartLineUpIcon from 'assets/chartLineUp.svg';
import announcementIcon from 'assets/announcement-speaker.svg';
import productBoxIcon from 'assets/box-icon.svg';
import moneyIcon from 'assets/payment.svg';
import truckIcon from 'assets/truck.svg';
import userDarkIcon from "assets/user-darkgray.svg";
import userLightIcon from "assets/user-light.svg";
import receiptDarkIcon from "assets/receiptDark.svg";
import receiptLightIcon from "assets/receiptLight.svg";

import updatesLightIcon from "assets/updates-light.svg";
import analyticsLightIcon from "assets/analytics-light.svg";
import homeLightIcon from "assets/home-light.svg";
import manageNDRLightIcon from "assets/manage-NDR-light.svg";
import manageordersLightIcon from "assets/manage-orders-light.svg";
import manageproductsLightIcon from "assets/manage-products-light.svg";
import paymentLightIcon from "assets/payment-light.svg";
import reportsLightIcon from "assets/reports-light.svg";
import RTOintelligenceLightIcon from "assets/RTO-intelligence-light.svg";
import sourceproductLightIcon from "assets/source-product-light.svg";
import supportLightIcon from "assets/support-light.svg";
import trainingLightIcon from "assets/training-light.svg";
import phonePlayLightIcon from "assets/phone-play-light.svg";
import phonePlayDarkIcon from "assets/phone-play-dark.svg";
import sparkleIcon from "assets/sparkle.svg";
import sparkleLightIcon from "assets/sparkle-light.svg";
import {COUNTRY_CODE} from "constants/store";
import {PERMISSIONS} from "utils/isAuthorized";

const PAGE_CONFIG = {
  analytics: {
    id: "/dashboard/analytics/overall",
    label: "Analytics",
    link: "/dashboard/analytics/overall",
    icon: chartLineUpIcon,
    iconLight: analyticsLightIcon,
    navId: 'dashboard-analytics-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.CRITICAL_ACCESS_ANALYTICS
  },
  home: {
    id: "/",
    label: "Home",
    link: "/",
    icon: homeIcon,
    iconLight: homeLightIcon,
    isHome: true,
    navId: 'home-nav-link',
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  manageOrders: {
    id: "/order/manage",
    label: "Manage Orders",
    link: "/order/manage/pendingcod",
    icon: clipboardTextIcon,
    iconLight: manageordersLightIcon,
    navId: 'manage-orders-nav-link',
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
    permission: PERMISSIONS.MANAGE_ORDERS
  },
  manageOrdersSummary: {
    id: "/order/manage",
    label: "Manage Orders Summary",
    link: "/order/manage/summary",
    navId: 'manage-orders-summary-nav-link',
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  manageNdr: {
    id: "/manage-ndr",
    label: "Manage NDR",
    link: "/manage-ndr/faileddelivery",
    icon: truckIcon,
    iconLight: manageNDRLightIcon,
    navId: 'manage-ndr-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.MANAGE_NDR
  },
  manageProducts: {
    id: "/manage-products",
    label: "Manage Products",
    link: "/manage-products/pushedToShopify",
    icon: productBoxIcon,
    iconLight: manageproductsLightIcon,
    navId: 'manage-products-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.MANAGE_PRODUCTS
  },
  sourceProduct: {
    id: "/source-product",
    label: "Source a Product",
    link: "/source-product",
    icon: productSourceIcon,
    iconLight: sourceproductLightIcon,
    tooltipText: "We will find your desired product and source it.",
    navId: 'source-product-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.SOURCE_PRODUCT
  },
  rtoIntelligence: {
    id: "/rto-intelligence",
    label: "RTO Intelligence",
    icon: locationMarkerIcon,
    iconLight: RTOintelligenceLightIcon,
    navId: 'rto-intelligence-nav-group',
    countryAccess: [COUNTRY_CODE.india],
    childNavs: {
      pincodes: {
        id: '/rto-intelligence/pincodes',
        label: "High RTO Pincode List",
        link: "/rto-intelligence/pincodes",
        navId: 'rto-pincodes-nav-link',
        countryAccess: [COUNTRY_CODE.india],
      },
      faqs: {
        id: '/rto-intelligence/faqs',
        label: "RTO FAQs",
        link: "/rto-intelligence/faqs",
        navId: 'rto-faqs-nav-link',
        countryAccess: [COUNTRY_CODE.india],
      }
    }
  },
  reports: {
    id: "/reports",
    label: "Reports",
    link: "/reports",
    icon: noteIcon,
    iconLight: reportsLightIcon,
    navId: 'reports-nav-link',
    countryAccess: [COUNTRY_CODE.india],
  },
  payments: {
    id: "/payments",
    label: "Payments",
    link: "/payments",
    icon: moneyIcon,
    iconLight: paymentLightIcon,
    navId: 'payments-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.CRITICAL_ACCESS_PAYMENTS
  },
  cloutTraining: {
    id: "cloutTraining",
    label: "Clout Training",
    isPopup: true,
    icon: cloutTrainingIcon,
    iconLight: trainingLightIcon,
    navId: 'clout-training-nav-button',
    countryAccess: [COUNTRY_CODE.india],
  },
  cloutUpdates: {
    id: "/clout-updates",
    label: "Clout Updates",
    link: "/clout-updates",
    icon: announcementIcon,
    iconLight: updatesLightIcon,
    navId: 'clout-updates-nav-link',
    countryAccess: [COUNTRY_CODE.india],
  },
  support: {
    id: "contactSupportPanel",
    label: "Support",
    isPopup: true,
    icon: headphoneIcon,
    iconLight: supportLightIcon,
    navId: 'contact-support-nav-button',
    countryAccess: [COUNTRY_CODE.india],
  },
  profile: {
    id: "/profile",
    label: "Profile",
    link: "/profile",
    navId: 'profile-nav-link',
    countryAccess: [COUNTRY_CODE.all],
  },
  search: {
    id: "/search",
    label: "Search",
    link: "/search",
    navId: 'search-nav-link',
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  storeManager: {
    id: "/store-manager",
    label: "Store Manager",
    link: "/store-manager",
    navId: 'store-manager-nav-link',
    countryAccess: [COUNTRY_CODE.all],
  },
  accountPopup: {
    id: "accountPopup",
    label: "Account",
    isPopup: true,
    icon: userDarkIcon,
    iconLight: userLightIcon,
    navId: 'account-popup-nav-button',
    countryAccess: [COUNTRY_CODE.all],
  },
  staffAccessManagement: {
    id: "/staff-access-management",
    label: "Staff Access Management",
    link: "/staff-access-management",
    navId: 'staff-access-management',
    countryAccess: [COUNTRY_CODE.all],
  },
  staffAccount: {
    id: "/staff-account",
    label: "Staff Account",
    link: "/staff-account",
    navId: 'staff-account',
    countryAccess: [COUNTRY_CODE.all],
  },
  productDetails: {
    id: "/product",
    label: "Product Details",
    link: "/product",
    navId: 'product-details',
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  category: {
    id: "/category",
    label: "Category",
    link: "/category",
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  collection: {
    id: "/collection",
    label: "Collection",
    link: "/collection",
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  buyerDsrRequest: {
    id: "buyerDsrRequest",
    label: "Buyer DSR Request",
    isPopup: true,
    icon: headphoneIcon,
    iconLight: supportLightIcon,
    navId: 'buyer-dsr-request-nav-button',
    countryAccess: [COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  videoFeed: {
    id: "/vide-feed",
    label: "Video Feed",
    link: "/video-feed",
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  discoveryVideoFeed: {
    id: "/video-feed/discover",
    label: "Discover",
    icon: phonePlayDarkIcon,
    iconLight: phonePlayLightIcon,
    navId: 'video-feed-discover',
    link: "/video-feed/discover",
    countryAccess: [COUNTRY_CODE.india, COUNTRY_CODE.us, COUNTRY_CODE.international],
  },
  valueAddedServices: {
    id: "vas",
    label: "Value Added Services",
    icon: sparkleIcon,
    iconLight: sparkleLightIcon,
    navId: 'dashboard-vas-nav-group',
    countryAccess: [COUNTRY_CODE.india],
    childNavs: {
      whatsapp: {
        id: "/dashboard/whatsapp",
        label: "Whatsapp",
        link: "/dashboard/whatsapp",
        navId: 'dashboard-whatsapp-nav-link',
        countryAccess: [COUNTRY_CODE.india],
      },
      metaFb: {
        id: "/dashboard/meta",
        label: "Meta Ads Insights",
        link: "/dashboard/meta",
        navId: 'dashboard-meta-nav-link',
        countryAccess: [COUNTRY_CODE.india],
      },
    }
  },
  wishlist: {
    id: "/wishlist",
    label: "Wishlist",
    link: "/wishlist",
    countryAccess: [COUNTRY_CODE.india],
  },
  gstInvoices: {
    id: "/gst-invoices",
    label: "GST Invoices",
    link: "/gst-invoices",
    icon: receiptDarkIcon,
    iconLight: receiptLightIcon,
    navId: 'gst-invoices-nav-link',
    countryAccess: [COUNTRY_CODE.india],
    permission: PERMISSIONS.CRITICAL_ACCESS_PAYMENTS
  },
}

export default PAGE_CONFIG;
